@font-face {
  font-family: 'HoeflerTitling-LightItalic';
  font-weight: normal;
  font-style: normal;
  src: url('/fonts/hoeflertitling-lightitalic-webfont.woff2') format('woff2'),
    url('/fonts/hoeflertitling-lightitalic-webfont.woff') format('woff');
}

@font-face {
  font-family: 'HoeflerTitling-Light';
  font-weight: normal;
  font-style: normal;
  src: url('/fonts/hoeflertitling-light-webfont.woff2') format('woff2'),
    url('/fonts/hoeflertitling-light-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/montserrat-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
       url('/fonts/montserrat-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/montserrat-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/montserrat-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/montserrat-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/montserrat-v14-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/Montserrat-SemiBold.ttf');
}

body {
  margin: 0;
  padding: 0;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

/* TYPOGRAPHY */

.caps-text-10 {
  font-size: 10pt;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 1.2px;
  text-align: left;
  text-transform: uppercase;
}

.caps-text-13 {
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 1.4px;
  text-align: left;
  text-transform: uppercase;
}

.header-bar-text {
  font-size: 13px;
  letter-spacing: 0.8384px;
}

.caps-text-10.grey, .caps-text-13.grey {
  color: rgb(135, 135, 135);
}

.caps-text-13.red {
  color: #ed2024;
}

/* LANDING */

.search-archive-button {
  background: none;
  border: none;
  width: 100%;
  margin-bottom: 24px;
}

.search-archive-button:focus,
.search-archive-button:hover,
.search-archive-button:active {
  outline: none;
  /* transform: translateY(-2px); */
}

.search-archive-img {
  width: 100%;
}

.search-archive-inputs {
  width: 100%;
  margin-bottom: -15px;
  position: relative;
  display: flex;
}

::placeholder {
  color: #7a7a7a;
}

.search-archive-input,
.search-archive-keyword-button,
.search-archive-primary-button {
  font-family: 'HoeflerTitling-LightItalic';
  font-size: 20pt;
  padding: 24px;
  margin: 3px;
  padding-top: 14px;
  padding-bottom: 14px;
  background: rgb(242,242,242);
  border: none;
  text-align: left;
  box-sizing: border-box;
  color: #333333;
  outline: none !important;
}

.search-archive-input {
  width: calc(50% + 6px);
}

.search-archive-input.modal-open {
  width: calc(75% + 18px);
  margin-right: 0;
}

.search-archive-keyword-button,
.search-archive-primary-button {
  width: 25%;
  color: #ed2024;
  cursor: pointer;
  text-align: center;
}

.search-archive-primary-button {
  text-align: center;
  font-family: "HoeflerTitling-Light", serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #ed2024;
  height: 67px;
}

.search-archive-primary-button.modal-open {
  margin-left: 0;
}

.search-archive-input.full {
  flex-grow: 1;
  flex-shrink: 1;
  height: 60px;
  margin-right: 0;
}

.search-archive-parent-secondary {
  display: flex;
}

.search-archive-secondary-button,
.search-archive-small-button {
  flex-grow: 0;
  flex-shrink: 0;
  border: none;
  outline: none !important;
  cursor: pointer;
  background: rgb(242, 242, 242);
  height: 60px;
  margin: 3px;
  padding-right: 16px;
  margin-left: 0;
  display: flex;
  align-items: center;
  font-family: "HoeflerTitling-Light", serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #ed2024;
  font-size: 16pt;
}

.search-archive-secondary-button.main-search {
  width: calc(25% - 6px);
}

.search-archive-secondary-button:hover .search-archive-secondary-button-img,
.search-archive-primary-button:hover .search-archive-secondary-button-img {
  transform: translateX(3px);
}

.search-archive-secondary-button-img {
  width: 35px;
  margin-left: 10px;
  transition: .2s ease;
}

.search-archive-secondary-button-img.primary {
  width: 44px;
}

.search-archive-small-button {
  padding: 0;
  height: inherit;
  margin-top: 12px;
  flex-grow: 1;
}

.search-archive-small-button-img {
  width: 35px;
  transition: .2s ease;
}

.search-archive-small-button:hover .search-archive-small-button-img {
  transform: translateX(3px);
}

.search-header {
  margin: 18px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .search-archive-keyword-button {
    display: none;
  }

  .search-archive-primary-button {
    width: auto;
    margin-left: 0;
    text-align: right;
  }

  .search-archive-secondary-label {
    display: none;
  }

  .search-archive-input {
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 0;
  }

  .search-archive-input.full {
    margin: 0;
  }

  .search-archive-secondary-button-img.primary {
    width: 35px;
  }

  .search-archive-secondary-button {
    margin: 0;
  }

  .search-header {
    margin: 18px 0;
    text-align: left;
  }
}

.keyword-modal {
  position: absolute;
  right: 3px;
  top: 76px;
  width: calc(50% - 5px);
  background: rgb(242, 242, 242);
  padding: 24px;
  padding-top: 36px;
  padding-bottom: 36px;
  box-sizing: border-box;
  display: flex;
}

.keyword-col {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
}

.keyword-header {
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 1.4px;
  color: #ed2024;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.keyword-selector::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 50%;
  border: 1px solid black;
  margin-right: 4px;
}

.keyword-selector.selected::before {
  background: radial-gradient(circle, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 51%);
}

.keyword-selector {
  font-size: 8pt;
  text-transform: uppercase;
  line-height: 18px;
  background: none;
  padding: 0;
  border: none;
  outline: none !important;
  cursor: pointer;
  letter-spacing: 0.9px;
  display: block;
  margin-bottom: 5px;
}

/* GALLERY */

.gallery-parent {
  width: 33.333%;
  display: inline-grid;
  grid-template-columns: 50% 50%;
}

.gallery-full {
  width: 100%;
  display: grid;
  grid-template-columns: 16.666% 16.666% 16.666% 16.666% 16.666% 16.666%;
}

@media only screen and (max-width: 768px) {
  .gallery-full {
    width: 100%;
    display: grid;
    grid-template-columns: 33.333% 33.333% 33.333%;
  }
}

.gallery-item {
  grid-column-start: auto;
  grid-row-start: auto;
  margin: 3px;
  margin-bottom: 0;
}

.gallery-item.bottom-margin {
  margin-bottom: 6px;
}

.gallery-item.big {
  grid-column-start: 1;
  grid-column-end: 3;
}

.gallery-spacer {
  grid-column-start: 1;
  grid-column-end: 3;
  height: 0;
}

.gallery-img {
  width: 100%;
  cursor: pointer;
  transition: filter 0.2s ease;
}

.gallery-img.hidden {
  opacity: 0.25;
  filter: brightness(1.25);
  -webkit-filter: brightness(1.25);
}

/* .gallery-parent {
  display: flex;
  flex-wrap: wrap;
}

.gallery-item {
  width: calc(16.666% - 5px);
  padding-right: 5px;
  padding-bottom: 5px;
}

.gallery-item.big {
  width: calc(33.336% - 6px);
}

.gallery-img {
  width: 100%;
  cursor: pointer;
} */

.gallery-img.sortable {
  cursor: move;
  margin-bottom: -4px;
}

.sortable-parent {
  padding: 3px;
}

.gallery-img.sortable:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.modal-status {
  margin-top: 10px;
  font-size: 10pt;
}

#copy-target {
  height: 0;
  opacity: 0;
}

.modal-button, .more-button {
  text-transform: uppercase;
  font-size: 16pt;
  color: rgb(180, 180, 180);
  background-color: rgb(242,242,242);
  padding: 20px;
  border: none;
  width: 100%;
  font-family: Montserrat, sans-serif;
  cursor: pointer;
  margin-top: 20px;
}

.modal-button.modal-link {
  display: inline-block;
  text-decoration: none;
  box-sizing: border-box;
  text-align: center;
}

.modal-button.red {
  color: #ed2024;
}

.modal-button.dark-gray {
  color: #333333;
}

.modal-window {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.25);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  transition: all 0.3s;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
}

.modal-window>div {
  width: 500px;
  max-width: 70%;
  transform: translate(0, 10%);
  position: relative;
  padding: 42px;
  background: #ffffff;
  padding-top: 27px;
  border-radius: 8px;
  box-shadow: 0 2px 18px -2px rgba(0, 0, 0, 0.4), 0 1px 6px -2px rgba(0, 0, 0, 0.15);
  text-align: left;
  z-index: 998;
  margin-top: 10vh;
  margin-bottom: 15vh;
}

.modal-fixed-inner {
  flex-grow: 0;
  width: 500px;
  max-width: 100%;
}

.modal-window.extended>div{
  display: flex;
  width: 800px;
}

.modal-extension {
  flex-grow: 1;
  flex-shrink: 1;
  padding-left: 18px;
  display: flex;
  flex-direction: column;
  margin-bottom: -12px;
  width: 300px;
  box-sizing: border-box;
}

.lightbox-list-parent {
  margin-top: 6px;
  flex-grow: 1;
  background: rgb(242,242,242);
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  max-height: min(80vh, 530px);
  overflow: auto;
}

.lightbox-list-item {
  padding: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Montserrat, sans-serif;
  outline: none !important;
  background: none;
  font-size: 12pt;
  width: 100%;
  text-align: left;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.lightbox-list-item:hover {
  background: rgba(0, 0, 0, 0.07);
}

.lightbox-list-item:active,
.lightbox-list-item:focus {
  background: rgba(0, 0, 0, 0.12);
}

.gallery-status-marker {
  width: 10px;
  margin-right: 8px;
}

.modal-close-img {
  width: 16px;
}

.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: -8px;
  text-align: center;
  top: 8px;
  width: 70px;
  text-decoration: none;
  cursor: pointer;
}

.modal-close:hover {
  color: black;
}

.modal-gallery-img {
  width: 500px;
  max-width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
}

@media only screen and (max-height: 685px) {
  .modal-gallery-img,
  .modal-fixed-inner,
  .modal-window>div {
    width: 400px;
  }
}

.modal-gallery-title {
  margin-top: 8px;
  line-height: 20px;
  font-size: 11pt;
}

.modal-gallery-bottom {
  display: flex;
  margin-bottom: -12px;
  align-items: flex-end;
}

.modal-gallery-bottom .left {
  width: 65%;
  flex-grow: 1;
}

.modal-gallery-bottom .right {
  width: 35%;
  text-align: right;
  flex-grow: 1;
}

@media only screen and (max-width: 768px) {
  .modal-window {
    background: white;
    align-items: flex-start;
  }

  .modal-window>div {
    margin-top: 24px;
    margin-bottom: 24px;
    transform: none;
  }

  .modal-window.gallery-item-modal {
    align-items: flex-start;
  }

  .modal-window.gallery-item-modal>div {
    margin-top: 0;
    max-width: 88%;
    padding: 0;
    padding-top: 36px;
    transform: none;
    box-shadow: none;
  }

  .modal-window.gallery-item-modal .modal-close {
    color: #aaa;
    line-height: 50px;
    font-size: 80%;
    position: absolute;
    right: 0;
    text-align: center;
    width: auto;
  }

  .modal-gallery-bottom {
    align-items: center;
  }

  .modal-window.gallery-item-modal.extended .modal-fixed-inner {
    display: none;
  }

  .modal-window.gallery-item-modal.extended .modal-extension {
    padding-left: 0;
  }
}

.modal-gallery-save {
  background: none;
  border: none;
  outline: none !important;
  font-family: Montserrat, sans-serif;
  color: rgb(180, 180, 180);
  font-size: 20pt;
  padding: 0;
  cursor: pointer;
  margin-bottom: -3px;
  transition: 0.3s ease;
}

.modal-gallery-save.open {
  opacity: 30%;
}

.modal-gallery-save-img {
  width: 18px;
  margin-right: 8px;
}

.modal-input {
  font-family: Montserrat, sans-serif;
  font-size: 13pt;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 18px;
  padding-top: 14px;
  padding-bottom: 14px;
  background: rgb(242, 242, 242);
  border: none;
  text-align: left;
  box-sizing: border-box;
  color: #333333;
  outline: none !important;
}

.create-lightbox-flex {
  margin-top: 26px;
  display: flex;
  align-items: center;
}

.create-lightbox-flex input {
  width: 0;
}

.create-lightbox-flex input, .create-lightbox-flex button {
  flex-shrink: 1;
  margin: 0;
}

.create-lightbox-flex.gallery-mod {
  margin-top: 16px;
}

.create-lightbox-button {
  height: 49px;
  border: none;
  background: rgb(242, 242, 242);
  outline: none !important;
  cursor: pointer;
}

.extended-back-button {
  background: none;
  outline: none !important;
  border: none;
}

.create-lightbox-go {
  width: 30px;
  padding-right: 10px;
  transition: .2s ease;
}

.create-lightbox-button:hover .create-lightbox-go {
  transform: translateX(3px);
}

/* LIGHTBOXES */

.lightbox-title-input {
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 1.4px;
  text-align: left;
  color: #ed2024;
  outline: none !important;
  padding: 0;
  border: none;
  background: none;
  width: 100%;
}

.header-row {
  display: flex;
  margin-bottom: 15px;
  margin-top: 35px;
  padding: 3px;
}

.half-col {
  width: 50%;
}

.half-col.right {
  text-align: right;
}

.lightboxes-parent {
  display: flex;
  flex-wrap: wrap;
}

.lightbox-item {
  width: calc(33.333% - 6px);
  margin: 3px;
  margin-bottom: 0;
}

.header-button {
  background: none;
  border: none;
  padding: 0;
  opacity: 1;
  transition: .3s ease;
  cursor: pointer;
  outline: none !important;
  min-width: 65px;
}

.header-button.top {
  min-width: 80px;
}

.header-button:hover {
  opacity: 0.7;
}

.header-button-img {
  height: 22px;
}

.header-button-text {
  font-size: 10pt;
  color: rgb(150,150,150);
  margin-top: 3px;
  letter-spacing: 0.5px;
}

.header-button-text.bottom-bar {
  color: rgb(255, 255, 255);
}

.header-button-text.red {
  color: #ed2024;
}

.gallery-lightbox-parent {
  position: relative;
  text-decoration: none;
}

.gallery-lightbox-parent .gallery-img {
  position: relative;
  z-index: 1;
  margin-top: -40px;
}

.gallery-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 4px);
  z-index: 2;
  background: rgb(0, 0, 0);
  background: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 80%);
}

.gallery-lightbox-title {
  top: 10px;
  height: 0;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  text-align: center;
  width: 100%;
  font-size: 15pt;
  color: white !important;
}

.gallery-lightbox-bottom {
  visibility: hidden;
  opacity: 0;
  transition: .3s ease;
  margin-top: -75px;
  position: relative;
  z-index: 5;
  width: 100%;
  text-align: center;
  margin-bottom: 25px;
}

.gallery-lightbox-parent:hover~.gallery-lightbox-bottom,
.gallery-lightbox-bottom:hover,
.gallery-img:hover~.gallery-lightbox-bottom {
  visibility: visible;
  opacity: 1;
}

.lightboxes-paginations {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin: 15px 0 30px;
  width: 100%;
  background: rgb(242, 242, 242);
}

.lightboxes-paginations-button {
  background-color: transparent;
  outline: none;
  border: none;
  padding: 10px 16px;
  cursor: pointer;
  font-weight: 500;
}
.lightboxes-paginations-button:disabled {
  cursor: not-allowed;
}

.lightbox-search-wrapper .search-archive-input {
  margin-left: 0;
  height: 46px;
  font-size: 17pt;
}
.lightbox-search-wrapper .search-archive-secondary-button {
  height: 46px;
  font-size: 14pt;
}

@media only screen and (max-width: 768px) {
  .bottom-for-mobile {
    display: flex;
    align-items: flex-end;
  }

  .lightbox-title-input {
    color: black;
  }

  .header-button-text {
    display: none;
  }

  .lightbox-item {
    width: calc(100% - 6px);
    margin-bottom: 8px;
  }

  .gallery-lightbox-bottom {
    visibility: visible;
    opacity: 1;
    text-align: left;
    margin-top: -50px;
  }

  .gallery-lightbox-bottom.lightbox-content {
    display: none;
  }

  .gallery-item.bottom-margin {
    margin-bottom: 0;
  }

  .header-button.top {
    min-width: 40px;
  }
}

/* SIGN IN AND SIGN UP */

.signin-button {
  font-family: Montserrat, sans-serif;
  background: none;
  color: rgb(140, 140, 140);
  border: none;
  padding: 0;
  outline: none !important;
  cursor: pointer;
}

.login-input {
  font-family: Montserrat, serif;
  font-size: 12pt;
  width: 100%;
  padding: 24px;
  padding-top: 14px;
  padding-bottom: 14px;
  background: rgb(242, 242, 242);
  border: none;
  text-align: left;
  box-sizing: border-box;
  color: rgb(140, 140, 140);
  outline: none !important;
  margin-top: -5px;
}

.page-button, .login-label {
  background: none;
  outline: none !important;
  border: none;
  color: rgb(140, 140, 140);
  font-size: 12pt;
  font-family: Montserrat, sans-serif;
  padding: 0;
  cursor: pointer;
  display: block;
  margin-top: 10px;
}

.login-label {
  text-transform: none !important;
  font-size: 10pt;
  font-weight: normal;
}

.page-button-parent {
  margin-top: 25px;
}

.login-button {
  background: none;
  outline: none !important;
  border: none;
  padding: 0;
  width: 100%;
  cursor: pointer;
  margin-top: 25px;
  transition: 0.3s ease;
}

.login-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.login-button-img {
  width: 100%;
}

.login-label {
  margin-top: 18px;
}

.login-label input {
  visibility: hidden;
  margin-right: 10px;
  margin-left: -1px;
}

.login-label input::before {
  cursor: pointer;
  visibility: visible;
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -3px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 50%;
  border: 1px solid rgb(140, 140, 140);
  margin-right: 4px;
}

.login-label input:checked::before {
  background: radial-gradient(circle, rgba(140, 140, 140, 1) 40%, rgba(0, 0, 0, 0) 41%);
}

/* Container */

@media only screen and (max-width: 959px) {
  .container, .sticky-nav-inner {
    width: auto !important;
    max-width: 90%;
  }
}

.container, .sticky-nav-inner {
  padding: 0 !important;
  width: 1002px;
  max-width: 90%;
  margin: 0 auto;
}

.container {
  position: absolute;
  left: 16px;
  right: 0;
}

body {
  overflow-y: scroll;
}

/* NAVIGATION */

.above-sticky-nav {
  display: none;
}

.sticky-nav {
  top: 0;
  position: fixed;
  z-index: 200;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.3);
  background: white;
  left: 0;
  right: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.sticky-nav-spacer {
  width: 100%;
  height: 38px;
  margin-bottom: 24px;
}

.sticky-nav-inner {
  display: flex;
  position: relative;
  left: 8px;
  flex-wrap: wrap;
  align-items: center;
}

.sticky-nav-inner .right {
  text-align: right;
  flex-grow: 1;
}

.sticky-nav-inner a,
.signout-button {
  font-family: Montserrat, sans-serif;
  text-decoration: none !important;
  color: #ed2024;
  border: none;
  outline: none !important;
  background: none;
  cursor: pointer;
  padding: 0;
}

.sticky-deselected {
  color: rgb(140, 140, 140) !important;
}

.sticky-selected {
  color: #ed2024 !important;
}

.sticky-disabled {
  cursor: not-allowed !important;
}

.slash-spacer {
  margin-left: 10px;
  margin-right: 10px;
}

.slash-spacer.mobile {
  display: none;
}

.header-avatar {
  width: 8px;
  margin-right: 6px;
}

.modal-status {
  font-size: 10pt;
  color: black;
  text-transform: none;
  font-weight: normal;
  margin-top: 15px;
  background: rgb(242,242,242);
  padding: 15px;
  border-left: 3px #ed2024 solid;
}

.pdf-viewer-parent {
  width: 100%;
  margin-top: 22px;
  height: 50vh;
}

.pdf-viewer-parent iframe {
  width: 100%;
  height: 100%;
}

.big-copyright {
  font-family: "HoeflerTitling-Light", serif;
  font-size: 36px;
  line-height: 48px;
  color: rgb(35, 35, 35);
  margin-bottom: 20px;
}

.big-copyright .red {
  color: #ed2024;
}

.mobile-show {
  display: none;
}

@media only screen and (max-width: 768px) {
  .container {
    position: absolute;
    left: 6px;
    right: 0;
  }

  .sticky-nav {
    box-shadow: none;
    background: transparent;
    position: absolute;
    z-index: inherit;
  }

  .sticky-nav-inner {
    margin-top: 27px;
    left: 0;
    width: 100% !important;
    max-width: 100%;
  }

  .sticky-nav-spacer {
    margin-top: 27px;
  }

  .slash-spacer.mobile {
    display: inline-block;
  }

  .sticky-nav-inner .right {
    text-align: left;
    flex-grow: 0;
  }

  .big-copyright {
    font-size: 23px;
    line-height: 31px;
  }

  .signout-button {
    color: rgb(135, 135, 135) !important;
  }

  .mobile-hide {
    display: none;
  }

  .mobile-show {
    display: inline-block;
  }

  .above-sticky-nav {
    display: block;
    top: -1px;
    width: 100%;
    height: 1px;
    position: fixed;
    z-index: 200;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.3);
    background: transparent;
    left: 0;
    right: 0;
    z-index: 1000;
  }
}

.upload-dropzone-parent {
  width: 100%;
  color: rgb(135, 135, 135);
  background-color: rgb(242, 242, 242);
  padding: 3px;
  box-sizing: border-box;
}

.files-parent {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  padding: 10px;
}

.single-file-parent {
  position: relative;
  margin: 5px;
  width: calc(25% - 10px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: space-between;
  box-sizing: border-box;
  background-color: white;
  border-radius: 8px;
}

.file-body {
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
}

.file-icon {
  position: relative;
  z-index: 1;
  width: 60px;
}

.file-type {
  position: relative;
  margin-top: -35px;
  text-align: center;
  z-index: 2;
  width: 100%;
  color: rgb(242, 242, 242);
  font-weight: bold;
  font-size: 0.7em;
}

.file-name {
  word-break: break-all;
  margin-top: 10px;
  font-size: 0.8em;
}

.file-buttons {
  position: relative;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  justify-content: space-around;
  padding: 5px;
  align-items: center;
  border-top: 1px solid rgb(242, 242, 242);
  flex-grow: 0;
}

.upload-dropzone {
  width: 100%;
  height: 30vh;
  min-height: 200px;
  max-height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(135, 135, 135);
  background-color: rgb(242, 242, 242);
  padding: 3px;
  box-sizing: border-box;
}

.file-parent {
  display: flex;
  flex-wrap: wrap;
}

/* .file-parent .pg-viewer-wrapper {
  width: calc(100% - 6px);
  margin: 3px;
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 2px 12px -2px rgba(0, 0, 0, 0.3), 0 1px 4px -2px rgba(0, 0, 0, 0.1);
} */

.pg-viewer-wrapper {
  width: 100%;
  max-height: 80vh;
  overflow: scroll;
}

.pg-viewer-wrapper .photo-viewer-container {
  width: 100% !important;
  height: inherit !important;
}

.pg-viewer-wrapper img {
  width: inherit !important;
  height: inherit !important;
}

.pg-viewer-wrapper video {
  width: 100% !important;
}

.file-error {
  text-align: center;
}

.file-tab {
  display: inline-block;
}

.resize-canvas{
  display: none;
}

.tiff-loader {
  text-align: center;
}